@import "~@/theme/variables", "~@/theme/mixins"


a
  color: $login-register-switcher-color

.text-default:hover
  text-decoration: none

.dark-theme
  & .login-register-switcher
    a
      color: $base-link-color-dark-theme
      &.text-default
        color: white!important

