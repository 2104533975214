@import "~@/theme/variables", "~@/theme/mixins"


.search-field
  border-radius: 12px
  border: $search-field-border-color
  display: flex
  .dropdown
    min-width: 122px
    border-radius: 0 10px 10px 0
    background: $white
    padding: 0 15px 0 10px
    & :deep(.value)
      line-height: 24px
      text-decoration: none
      font-size: $extra-small-font-size
      font-weight: $light
    & :deep(.options)
      min-width: 120px
    &::before
      position: absolute
      left: 0
      top: 4px
      bottom: 4px
      width: 1px
      background: #DCDCDC
      content: ''

