@import "~@/theme/variables", "~@/theme/mixins"


.dark-theme
  & .search-field .dropdown .value
    color:  $search-field-dropdown-value-color

  & .dropdown
    & .value
      color: $base-text-color-dark-theme

      &::after
        border-top-color: $base-text-color-dark-theme

    & .options
      background: $basic-bg-dark-theme
      color: $white
    & .option
      color: $white
      &:hover
        background: $dropdown-item-hover-bg-dark-theme
        color: $dropdown-item-hover-color-dark-theme

.dropdown
  position: relative
  cursor: pointer

.value
  font-size: 14px
  text-transform: uppercase
  text-decoration: underline
  color:  $base-text-color
  .light &
    text-transform: none
    text-decoration: none
    color: $dropdown-color
    font-size: 18px
  &::after
    display: inline-block
    width: 0
    height: 0
    border-style: solid
    border-width: 5px 4px 0 4px
    border-color: #505050 transparent transparent transparent
    content: ''
    transform: translate(5px, -1px)
    .light &
      border-top-color: $dropdown-color
      transform: translate(10px, -3px)

.options
  position: absolute
  top: 100%
  right: 0
  min-width: 170px
  font-size: 12px
  font-weight: $light
  background: $white
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16)
  cursor: default
  text-align: left
  border-radius: 5px
  overflow: hidden
  z-index: 1
  .light &
    right: auto
    left: 0

.option
  display: block
  padding: 10px 20px
  color: #505050
  white-space: nowrap
  &.line
    box-shadow: 0 1px rgba(148, 151, 153, 0.2)
  &.icon
    position: relative
    padding-left: 45px

.option:hover
  color: $dropdown-item-hover-color
  background: $dropdown-item-hover-bg
  cursor: pointer
  .light &
    background: rgba(148, 151, 153, 0.1)
    color: inherit

$icons: (cloud-cdn, 14, 14), (settings, 14, 14), (cloud-record, 14, 14), (cloud-computing, 14, 14), (cloud-player, 14, 14), (cloud-publish, 14, 14), (cloud-s3, 14, 14), (cloud-stats, 14, 14), (tasks, 14, 14), (document, 14, 14)

@each $icon, $width, $height in $icons
  $width: #{$width}px
  $height: #{$height}px
  .#{$icon}::after
    position: absolute
    left: 16px
    top: 10px
    width: $width
    height: $height
    background-image: url('~@/assets/#{$icon}.png')
    background-size: $width $height
    content: ''
    +retina
      background-image: url('~@/assets/#{$icon}@2x.png')

