@import "~@/theme/variables", "~@/theme/mixins"


.tabs::after
  display: block
  height: 1px
  margin: 0
  background: #ECECEC
  content: ''

.tab
  display: inline-block
  padding: 10px
  margin: 6px
  cursor: pointer
  color: $tabs-tab-color

  &.active
    color: $tabs-tab-color-active
  &:nth-child(1)
    margin-left: 0

.no_underline::after
  display: none

.dark-theme
  & .tab
    color: $base-text-color-dark-theme
    &.active
      color: $base-link-color-dark-theme

