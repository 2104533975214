
@each $side in top, right, bottom, left
  @for $i from 1 through 8
    @each $property in margin, padding
      .#{ $property }-#{ $side }#{ if( $i > 1, '-' + $i + 'x', '' ) }
        @if $side == top or $side == bottom
          #{ $property }-#{ $side }: $vertical-step * $i
        @else
          #{ $property }-#{ $side }: $gutter-width * $i
  @for $i from -4 through -1
    .margin-#{ $side }-negative#{ if( $i < -1, '-' + $i + 'x', '' ) }
      @if $side == top or $side == bottom
        margin-#{ $side }: $vertical-step * $i
      @else
        margin-#{ $side }: $gutter-width * $i
  .no-padding-#{ $side }
    padding-#{ $side }: 0

@for $i from 3 through 4
  .line-height-#{ $i + 'x' }
    line-height: $vertical-step * $i

.line-height-input
  line-height: $extra-small-font-size * 2

.pull
  @each $side in right, left
    &-#{$side}
      float: $side

@each $property in block, inline-block, inline
  .#{ $property }
    display: $property !important

.text
  @each $alignment in left, right, center, justify
    &-#{$alignment}
      text-align: $alignment
  @each $type, $weight in ( light: $light, normal: $normal, bold: $bold )
    &-#{$type}
      font-weight: $weight
  &-big
    font-size: 20px
  &-giant
    font-size: 24px
  &-uppercase
    text-transform: uppercase
  &-small
    font-size: $small-font-size !important
  &-extra-small
    font-size: $extra-small-font-size !important
  &-default
    color: #505050 !important
    font-weight: $normal
    text-decoration: none
    cursor: default !important
  &-light
    font-weight: $light
  &-danger, &-error
    color: $red !important
  &-error
    text-align: right
    font-weight: $light
    font-size: $small-font-size
  &-white
    color: $white
  &-blue
    color: $blue
  &-ellipsis
    display: block
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
  &-muted
    color: #B9B9B9

.clearfix
  +clearfix

.overflow
  @each $value in hidden, scroll, auto
    &-#{$value}
      overflow: $value

.plus
  &::before
    display: inline-block
    width: 18px
    height: 18px
    margin-right: 5px
    background: url('assets/plus-button.svg') no-repeat
    background-size: 18px
    content: '\00a0'

.clickable
  cursor: pointer

.not-allowed
  cursor: not-allowed
  filter: grayscale(150%)

.flex
  display: flex

.base-wrapper
  margin: 10px
  padding: 10px
  border-radius: 8px
  background: $white
  box-shadow: 1px 1px 5px #EBEBEB
  transition: .3s

  &.middle-padding
    padding: 20px

  &:hover
    box-shadow: 1px 1px 7px #B6B9B9

.dark-theme
  .base-wrapper
    background: $base-wrapper-bg-dark-theme
    box-shadow: 1px 1px 5px $base-wrapper-bg-dark-theme
