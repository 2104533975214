// Responsive utilities

//
// More easily include all the states for responsive-utilities.less.
// [converter] $parent hack
=responsive-visibility($parent)
  #{$parent}
    display: block !important

  table#{$parent}
    display: table

  tr#{$parent}
    display: table-row !important

  th#{$parent},
  td#{$parent}
    display: table-cell !important

// [converter] $parent hack
=responsive-invisibility($parent)
  #{$parent}
    display: none !important
