.bb-tooltip-container
  width: 240px

  & table.bb-tooltip
    padding: 8px
    background: rgba(0, 0, 0, 0.5)
    box-shadow: 1px 1px 3px $base-wrapper-bg-dark-theme
    border: none
    border-radius: 8px
    overflow: hidden
    color: $white

    & td
      border-bottom: none

    & th
      background: transparent
      text-align: center
      text-decoration: none

    & td
      background: transparent
      font-size: 10px
      text-align: right

      &.name
        display: block
        width: 140px
        text-align: left
        text-overflow: ellipsis
        overflow: hidden

.bb .bb-main text

.bb .bb-axis-x .tick text
  font-size: 7px

.bb-chart-arcs .bb-chart-arcs-title
  font-size: 12px!important
.bb-chart-arc text
  font-size: 9px!important
  text-shadow: 1px 1px 2px black

.dark-theme
  .bb-chart-arcs .bb-chart-arcs-title
    fill: $base-text-color-dark-theme!important
