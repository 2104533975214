@import "~@/theme/variables", "~@/theme/mixins"

.wrapper
  max-height: 120px
  overflow-y: auto
  overflow-x: hidden
  border: 1px solid #dad7d7
  border-radius: 3px
  padding: 10px 0 10px 10px

.file
  display: flex

.file-name
  display: inline
  margin-right: 10px
  width: 50%
  overflow: hidden
  text-overflow: ellipsis

  &.invalid
    color: red
