@import variables
@import mixins

// Grid system
// --------------------------------------------------

// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container
  +container-fixed

// Row
//
// Rows contain and clear the floats of your columns.

.row
  +make-row

// Columns
//
// Common styles for small and large grid columns

+make-grid-columns

// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

+make-grid(xs)

// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

@media (min-width: $screen-sm-min)
  +make-grid(sm)

// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@media (min-width: $screen-md-min)
  +make-grid(md)

@import responsive-utilities
