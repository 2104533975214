@import "~@/theme/variables", "~@/theme/mixins"


.dark-theme
  & .modal-wrapper
    background: $modal-bg-dark-theme

.modal-mask
  position: fixed
  top: 0
  left: 0
  z-index: 1000
  width: 100%
  height: 100%
  background: fade_out(#3E3E3E, 0.75)
  overflow: auto

.modal
  position: absolute
  top: 50%
  left: 50%
  width: 560px
  max-width: 100%
  max-height: 100%
  transform: translate(-50%, -50%)
  &.small
    max-width: 420px

  &-wrapper
    padding: 25px 20px
    background: $white
    border-radius: 3px
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16)

  &-header
    position: relative
    margin: 0 -20px 15px
    padding: 0 20px 15px 20px
    border-bottom: 1px solid #ECECEC
    font-size: 18px
    cursor: default

  &-content
    margin-bottom: 20px
    font-size: $small-font-size
    &:last-child
      margin-bottom: 0

  &-footer
    +clearfix
    button
      float: right
      & + button
        float: left

