@import "~@/theme/variables", "~@/theme/mixins"


.list
  font-size: 12px

.list, .title
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16)

.title
  padding: 15px
  background: $collapsing-list-bg
  background-image: $collapsing-list-bg-linear
  color: #FFFFFF
  border-radius: 5px 5px 0 0
  cursor: pointer

.items
  overflow: hidden
  line-height: 20px
  max-height: 0
  transition: max-height 0.75s ease-in-out
  background: #FFFFFF
  &.expanded
    max-height: 235px
    overflow: auto

.item
  padding: 10px 15px
  box-shadow: 0 1px #ECECEC
  cursor: pointer

  &-name
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

.item-icon
  float: right
  font-size: 8px

.cross-icon, .arrow-icon
  float: right
  width: 14px
  height: 14px
  background-size: 14px 14px
  margin-left: 14px
  cursor: pointer

.cross-icon
  background-image: url('~@/assets/cross.svg')

.arrow-icon
  background-image: url('~@/assets/arrow-white.svg')
  transition: transform 0.5s ease-in-out
  transform: rotateZ(270deg)
  &.expanded
    transform: rotateZ(90deg)

.success-icon, .error-icon
  width: 20px
  height: 20px
  background-size: 20px 20px

.success-icon
  background-image: url('~@/assets/success.svg')

.error-icon
  background-image: url('~@/assets/error.svg')

.dark-theme
  & .list
    & .title
      background: $collapsing-list-bg-dark-theme

    & .items
      background: $gray-dark-dark-theme

