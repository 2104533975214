@import "~@/theme/variables", "~@/theme/mixins"


.actions
  &-title
    margin-bottom: $vertical-step * 2
    text-align: center
    text-transform: uppercase
    text-decoration: underline
    font-size: $small-font-size
    margin-bottom: 50px
  &-icon-block
    min-height: 45px
    margin-bottom: 20px
    padding-bottom: 20px
    box-shadow: 0 1px rgba(112, 112, 112, 0.1)
    text-align: center
    &:last-child, &.empty
      box-shadow: none
    &.empty
      min-height: 42px
  &-icon
    display: inline-block
    width: 22px
    height: 22px
    margin: 0 7px
    cursor: pointer
    vertical-align: top
    @each $icon in (delete, rename)
      &.#{$icon}
        background: url(assets/#{$icon}.svg) no-repeat
        background-size: contain

