table
  width: 100%
  margin-bottom: 24px
  table-layout: fixed
  border-collapse: collapse

th
  font-weight: normal
  text-transform: uppercase
  text-decoration: underline
  font-size: 12px

th, td
  padding: 10px 0
  border-bottom: 1px solid #ECECEC
  text-align: center
  cursor: default

  &.info
    color: $pseudo-link-color
    font-size: $small-font-size
    width: 60px
    cursor: pointer

  &.lock
    width: 60px

  &.td-checkbox,
  &.th-checkbox,
  &.td-delete,
  &.th-delete,
  &.td-edite,
  &.th-edite
    width: 32px
    padding: 0 10px

  &.td-delete
    background: url('~@/assets/delete.svg') no-repeat center center
    background-size: 15px
    cursor: pointer

  &.td-edite
    background: url('~@/assets/edite.svg') no-repeat center center
    background-size: 15px
    cursor: pointer

th
  text-transform: uppercase
  text-decoration: underline
  cursor: pointer
  &.active
    &::after
      content: ' ↓'
  &.up
    &::after
      content: ' ↑'
  &.th-not-cursor
    cursor: default

td
  font-size: $extra-small-font-size

  &.error
    color: $red

  &.ellipsis
    text-overflow: ellipsis
    overflow: hidden

  &.td-pseudo-link
    color: $settings-pseudo-link
    cursor: pointer

  &.lock
    background: url('~@/assets/padlock-lock.svg') no-repeat center center
    background-size: 13px
  &.unlock
    background: url('~@/assets/padlock-unlock.svg') no-repeat center center
    background-size: 15px


.dark-theme
  & td.td-pseudo-link
    color: $base-link-color-dark-theme

  & td.info
    color: $base-link-color-dark-theme
