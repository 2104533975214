@import "~@/theme/variables", "~@/theme/mixins"

.notification
  position: fixed
  top: 10px
  right: 10px
  width: clamp(300px, 100%, 500px)
  padding: $vertical-step
  border: $border-basic
  border-radius: calc($border-radius-basic * 3)
  background: $white
  font-size: $extra-small-font-size
  box-shadow: 1px 1px 6px $base-wrapper-bg-dark-theme
  cursor: default

  &__header
    margin-bottom: $vertical-step
    font-size: 14px
    color: $second-text-color

  &__text
    margin-bottom: $vertical-step

  &__subtext
    margin-bottom: $vertical-step
    font-size: 10px
    color: $red

  &__link
    margin-left: auto
    color: $blue
    font-weight: 400

  &__btn
    position: absolute
    right: 5px
    top: 5px
    width: 18px
    height: 18px
    background: url(assets/close.svg) no-repeat
    background-size: 18px 18px
    cursor: pointer
    transform: rotateZ(45deg)
