@import "~@/theme/variables", "~@/theme/mixins"


.actions
  padding: 10px 16px

  &-title
    margin-bottom: 20px
    text-align: center
    text-transform: uppercase
    text-decoration: underline
    font-size: $small-font-size

  &-icon-block
    min-height: 45px
    box-shadow: 0 1px rgba(112, 112, 112, 0.1)
    text-align: center

    &:last-child, &.empty
      box-shadow: none

    &.empty
      min-height: 42px

  &-icon
    display: inline-block
    width: 22px
    height: 22px
    margin: 0 7px
    cursor: pointer
    vertical-align: top
    @each $icon in (add-bucket, add-folder, upload, upload-link, download, delete, rename, hide, move, transcode)
      &.#{$icon}
        background: url(assets/#{$icon}.svg) no-repeat
        background-size: contain

.s3-actions
  height: 24px
  width: 24px
  margin: 16px auto
  border-radius: 50%
  background: $white
  box-shadow: 1px 1px 5px #EBEBEB
  cursor: pointer

  &:hover
    box-shadow: 1px 1px 7px #B6B9B9

  &__logout
    height: 23px
    width: 23px
    margin: auto
    background: url(assets/logout.svg) no-repeat
    background-size: cover

.dark-theme .actions
  &-icon
    filter: $action-icon-filter-dark-theme

  .s3-actions
    background: $base-wrapper-bg-dark-theme
    box-shadow: 1px 1px 5px $base-wrapper-bg-dark-theme
    &:hover
      box-shadow: 1px 1px 7px $base-wrapper-bg-dark-theme
    &__logout
      filter: brightness(2)

