
label
  &.normal
    line-height: 32px
  &.big
    line-height: 42px
  &[for]
    cursor: pointer
  &.required
    &::after
      content: ' *'

.disabled, [disabled]
  opacity: 0.5
  &, & *
    //cursor: default !important
    cursor: not-allowed !important

input[readonly]
  color: #B1B1B1
  cursor: default !important

select
  display: block
  width: 100%
  height: 24px
  border: 1px solid #D2D2D2
  background: none
  outline: 0
  transition: 0.1s border-color ease-out
  box-shadow: none
  border-radius: 2px
  &:focus
    border-color: #F5B71D

.has-error, .has-warning
  margin-top: $vertical-step
  text-align: right
  font-weight: $light
  font-size: $small-font-size

.has-error
  color: $red

.has-warning
  color: #e67e22

.warning input
  border-color: #e67e22

.radio, .checkbox
  display: block
  margin-bottom: 10px
  font-size: 12px !important
  cursor: pointer
  input[type="radio"], input[type="checkbox"]
    margin-right: 10px

div
  .v-select
    font-size: 12px
    .open-indicator
      display: none
    .dropdown-menu
      top: auto
      bottom: 100%
      border: 1px solid #D2D2D2
      border-radius: 2px 2px 0 0
      overflow: auto
    &, &.open
      .dropdown-toggle
        border-color: #D2D2D2
        border-radius: 2px
    .selected-tag
      height: 20px
      line-height: 20px
      margin: 1px
      border-color: #FAB60A
      background: none
      color: #8E8E8E
      padding: 0 5px 1px
      .close
        color: #FAB60A
        opacity: 1
        font-size: 16px
    input[type="search"]
      &, &:focus
        height: 22px

.checkbox
  display: inline-block
  position: relative
  margin: 0
  min-height: 12px
  min-width: 12px
  line-height: 14px
  user-select: none

input[type="checkbox"]
  display: none
  &[disabled]+label
    cursor: not-allowed

label
  display: inline-block
  padding-left: 16px
  &.text
    padding-left: 22px
  &:hover
    &::before
      border-color: #3592C1
  &::before
    position: absolute
    left: 0
    top: 0
    width: 12px
    height: 12px
    border: 1px solid #DCDCDC
    border-radius: 3px
    background: #FFFFFF
    content: ''
  input:checked + &
    &::before
      background: $checkbox-color
      border-color: $checkbox-color
    &:hover
      &::before
        border-color: #FFFFFF
  input:checked + &::after
    position: absolute
    left: 2px
    top: 2px
    width: 7px
    height: 7px
    background: url(~@/assets/checkbox.svg) no-repeat
    background-size: 7px 7px
    content: ''

.dark-theme
  & .group-select
    color: $white

  & select
    color: $white
    background: $basic-bg-dark-theme
