@import "~@/theme/variables", "~@/theme/mixins"


.checkbox
  display: inline-block
  position: relative
  margin: 0
  min-height: 12px
  min-width: 12px
  line-height: 12px
  user-select: none

input[type="checkbox"]
  display: none

label
  display: inline-block
  &.text
    padding-left: 22px
  &:hover
    &::before
      border-color: #3592C1
  &::before
    position: absolute
    left: 0
    top: 0
    width: 12px
    height: 12px
    border: 1px solid #DCDCDC
    border-radius: 3px
    background: #FFFFFF
    content: ''
  input:checked + &
    &::before
      background: $checkbox-color
      border-color: $checkbox-color
    &:hover
      &::before
        border-color: #FFFFFF
  input:checked + &::after
    position: absolute
    left: 2px
    top: 2px
    width: 7px
    height: 7px
    background: url(~@/assets/checkbox.svg) no-repeat
    background-size: 7px 7px
    content: ''

