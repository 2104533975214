@import "~@/theme/variables", "~@/theme/mixins"


.recovery-view
  width: 350px
  margin: auto
  padding-top: 100px

.form
  width: 100%
  padding: 25px 20px
  background: #FFFFFF
  border: $border-basic-light
  border-radius: $border-radius-basic

.inline-block
  width: 100%

.button-wrap
  margin-top: 20px
  text-align: end

