@import "~@/theme/variables", "~@/theme/mixins"


.restore
  padding-top: 100px

  .login
    width: 350px
    margin: auto

  .form
    padding: 25px 20px
    background: #FFFFFF
    border: $border-basic-light
    border-radius: $border-radius-basic

  .line
    height: 1px
    background: #ECECEC
    width: 185px
    margin: 0 auto $vertical-step auto

  a
    color: $login-link-color

.dark-theme
  & .restore
    & .form
      background: $modal-bg-dark-theme

