// Grid system
//
// Generate semantic grid columns with these mixins.

// Centered container element
=container-fixed($gutter: $grid-gutter-width)
  margin-right: auto
  margin-left: auto
  padding-left: calc($gutter / 2)
  padding-right: calc($gutter / 2)

  +clearfix

// Creates a wrapper for a series of columns
=make-row($gutter: $grid-gutter-width)
  margin-left: calc($gutter / -2)
  margin-right: calc($gutter / -2)

  +clearfix

// Generate the extra small columns
=make-xs-column($columns, $gutter: $grid-gutter-width)
  position: relative
  float: left
  width: percentage(calc($columns / $grid-columns))
  min-height: 1px
  padding-left: calc($gutter / 2)
  padding-right: calc($gutter / 2)

=make-xs-column-offset($columns)
  margin-left: percentage(calc($columns / $grid-columns))

=make-xs-column-push($columns)
  left: percentage(calc($columns / $grid-columns))

=make-xs-column-pull($columns)
  right: percentage(calc($columns / $grid-columns))

// Generate the small columns
=make-sm-column($columns, $gutter: $grid-gutter-width)
  position: relative
  min-height: 1px
  padding-left: calc($gutter / -2)
  padding-right: calc($gutter / -2)

  @media (min-width: $screen-sm-min)
    float: left
    width: percentage(calc($columns / $grid-columns))

=make-sm-column-offset($columns)
  @media (min-width: $screen-sm-min)
    margin-left: percentage(calc($columns / $grid-columns))

=make-sm-column-push($columns)
  @media (min-width: $screen-sm-min)
    left: percentage(calc($columns / $grid-columns))

=make-sm-column-pull($columns)
  @media (min-width: $screen-sm-min)
    right: percentage(calc($columns / $grid-columns))

// Generate the medium columns
=make-md-column($columns, $gutter: $grid-gutter-width)
  position: relative
  min-height: 1px
  padding-left: $gutter / 2
  padding-right: $gutter / 2

  @media (min-width: $screen-md-min)
    float: left
    width: percentage(calc($columns / $grid-columns))

=make-md-column-offset($columns)
  @media (min-width: $screen-md-min)
    margin-left: percentage(calc($columns / $grid-columns))

=make-md-column-push($columns)
  @media (min-width: $screen-md-min)
    left: percentage(calc($columns / $grid-columns))

=make-md-column-pull($columns)
  @media (min-width: $screen-md-min)
    right: percentage(calc($columns / $grid-columns))
