/* Color palette */
$red: #F51D1D
$white: #FFFFFF
$base-text-color: #707070
$blue: #1496f0

/* Sizes */
$phone-screen-width: 768px
$tablet-screen-width: 992px
$max-width: 1110px
$min-width: 1000px
$gutter-width: 15px
$vertical-step: 10px

/* Fonts */
$first-text-color: #939292
$second-text-color: #222222
$third-text-color: $blue

$font-family: Roboto, sans-serif
$base-font-size: 16px
$big-font-size: 20px
$small-font-size: 14px
$extra-small-font-size: 12px
$light: 300
$normal: 400
$bold: 600

/*  Etc */
$transition-time: 0.1s
$transition-timing: ease-out
$pseudo-link-color: $blue
$border-basic: 1px solid rgba(112, 112, 112, .5)
$border-basic-light: 1px solid rgba(112, 112, 112, .1)
$border-radius-basic: 3px
$base-gradient: linear-gradient(90deg, #1496f0, #075e9c)

/* App */
$app-bg: url('assets/space.png') no-repeat center 66px

/* Header */
$header-bg: $base-gradient
$header-border: none
$theme-switcher-bg: url('assets/dark-theme.svg') no-repeat

/* Btn */
$button-bg: $base-gradient
$button-color: $white
$button-stroke-color: $base-text-color

/* CollapsingList */
$collapsing-list-bg: $blue
$collapsing-list-bg-linear: $base-gradient

/* Dropdown */
$dropdown-color: $white
$dropdown-item-hover-bg: $blue
$dropdown-item-hover-color: $white

/* SideNave */
$sidenav-ava-color: $white
$sidenav-ava-bg: $base-gradient
$sidenav-stat-bar-bg: $base-gradient
$sidenav-stat-bar-warning: #CA513C
$sidenav-stat-bar-border: $border-basic
$sidenav-dropdown-item-bg: $blue
$sidenav-dropdown-item-color: $white

/* Streams */
$streams-title-ava-bg: $blue
$streams-title-ava-color: $white

/* Switcher */
$switcher-head-color: $blue

/* Tabs */
$tabs-tab-color: $blue
$tabs-tab-color-active: #707070

/* Tasks */
$tasks-modal-first-column-color: $base-text-color

/* input */
$checkbox-color: $blue
$search-field-border-color: 1px solid $blue

/* Settings */
$settings-pseudo-link: $blue

/* LanguageSwitcher */
$language-color: $white

/* LoginRegisterSwitcher */
$login-register-switcher-color: $blue

/* Login */
$login-link-color: $blue

/* CDNStats */
$donuts-chart-blur: blur(2px)

// Dark theme
$dark-theme-gray: #313036
$gray-light-dark-theme: #505050
$gray-dark-dark-theme: #222222
$base-text-color-dark-theme: #dfdfdf
$base-dark-text-color-dark-theme: $dark-theme-gray
$base-link-color-dark-theme: #faf9c4
$basic-bg-dark-theme: #424242
$app-bg-dark-theme: $dark-theme-gray
$navigation-bg-dark-theme: $dark-theme-gray
$modal-bg-dark-theme: $gray-dark-dark-theme
$header-bg-dark-theme: linear-gradient(90deg,#232427,#121316)
$language-color-dark-theme: $white
$theme-switcher-bg-dark-theme: url('assets/light-theme.svg') no-repeat
$sidenav-ava-bg-dark-theme: linear-gradient(90deg,#232427,#121316)
$sidenav-ava-color-dark-theme: $white
$settings-pseudo-link-dark-theme: $white
$collapsing-list-bg-dark-theme: $gray-light-dark-theme
$button-color-dark-theme: $base-text-color-dark-theme
$button-stroke-color-dark-theme: $base-text-color-dark-theme
$action-icon-filter-dark-theme: brightness(2)
$sidenav-dropdown-item-hover-color-dark-theme: $white
$dropdown-item-hover-bg-dark-theme: $dark-theme-gray
$dropdown-item-hover-color-dark-theme: $base-text-color-dark-theme
$search-field-dropdown-value-color: $dark-theme-gray
$base-wrapper-bg-dark-theme: #232427
