.datepicker
  .dp__input_wrap
    margin-left: 16px
    width: 270px

    input
      font-size: 11px
      padding: 0 0 0 32px

      border-radius: 1px
      border-color: #D2D2D2

    .dp__input_icons
      &:nth-last-child(1)
        display: none

  .dp__preset_ranges
    min-width: 150px

.dp__action.dp__select
  color: $blue

.dark-theme
  & .dp__instance_calendar,
  & .dp__button,
  & .dp__action_row
    background: $base-wrapper-bg-dark-theme

  & .dp__calendar_item,
  & .dp__calendar_header_item,
  & .dp__month_year_select,
  & .dp__selection_preview
    color: $base-text-color-dark-theme

  & .dp__range_between
    background: #533434
  & .dp__overlay_row
    background: $base-wrapper-bg-dark-theme

  & .dp__time_display
    color: $blue
