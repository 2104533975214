@import "~@/theme/variables", "~@/theme/mixins"
.button_up
  display: flex
  justify-content: center

  width: 50px
  height: 50px

  position: fixed
  right: 70px
  transform: translate(100%)

  background: linear-gradient(317.7deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 105.18%), #EBECF0
  background-blend-mode: soft-light, normal
  border: 1px solid rgba(255, 255, 255, 0.4)
  box-shadow: 3px 3px 3px #A6ABBD, 2px 1px 3px #FAFBFF
  border-radius: 50%
  bottom: 4rem

  cursor: pointer
  outline: 0
  transition: .3s
  z-index: 99

  &:hover
    background: linear-gradient(317.7deg, rgba(0, 0, 0, 0.8) 0%, rgba(255, 255, 255, 0.4) 105.18%), #EBECF0

  &:active
    transform: scale(.99) translate(100%, 2px)
    background: linear-gradient(317.7deg, rgba(0, 0, 0, 0.9) 0%, rgba(255, 255, 255, 0.3) 105.18%), #EBECF0
    box-shadow: -3px 1px 1px #A6ABBD, 0 0 2px #FAFBFF

  &:before
    content: ""
    display: block
    position: absolute
    border-top: 2px solid #333
    border-left: 2px solid #333
    top: 45%
    transform: rotate(45deg)
    height: 25%
    width: 25%
    transition: .3s

.dark-theme .button_up
  background: linear-gradient(145deg, #222323, #292a2a)
  box-shadow:  5px 5px 9px #202121, -5px -5px 9px #2c2d2d

  &:before
    border-top: 2px solid #606477
    border-left: 2px solid #606477
