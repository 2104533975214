@import "~@/theme/variables", "~@/theme/mixins"


.navigation
  width: 300px
  min-width: 300px
  padding: 40px 25px 20px
  background: #FFFFFF
  border-right: 1px solid rgba(112, 112, 112, 0.1)

.ava
  width: 91px
  height: 91px
  margin: 0 auto 25px auto
  line-height: 91px
  border-radius: 50%
  background: $sidenav-ava-bg
  color: $sidenav-ava-color
  font-size: 43px
  text-align: center
  font-weight: 100

.profile-menu
  position: relative
  margin-bottom: 100px

.email
  position: relative
  padding-bottom: 10px
  text-align: center
  cursor: pointer
  font-size: $extra-small-font-size
  z-index: 3
  &::after
    display: inline-block
    width: 0
    height: 0
    border-style: solid
    border-width: 5px 4px 0 4px
    border-color: #505050 transparent transparent transparent
    content: ''
    transform: translate(5px, -1px)

  &:hover +.dropdown
    top: 50%
    display: block
    transform: translate(-50%, 0)
    opacity: 1
    z-index: 2

.dropdown
  position: absolute
  top: -30%
  left: 50%
  min-width: 170px
  transform: translate(-50%, -50%)
  font-size: 12px
  font-weight: $light
  background: $white
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16)
  cursor: default
  text-align: left
  transition: 150ms transform linear, 300ms opacity linear
  opacity: 0
  border-radius: 5px
  overflow: hidden

  &:hover
    top: 50%
    display: block
    transform: translate(-50%, 0)
    opacity: 1
    z-index: 2

.dropdown-item
  display: block
  padding: 10px 20px
  color: #505050
  &.line
    box-shadow: 0 1px rgba(148, 151, 153, 0.2)

.dropdown-item:hover
  color: $sidenav-dropdown-item-color
  background: $sidenav-dropdown-item-bg
  cursor: pointer

.stats, .actions
  margin: 16px 0
  padding: 10px 16px
  border-radius: 8px
  background: $white
  box-shadow: 1px 1px 5px #EBEBEB
  &:hover
    box-shadow: 1px 1px 7px #B6B9B9

  &-title
    margin-bottom: $vertical-step * 2
    text-align: center
    text-transform: uppercase
    text-decoration: underline
    font-size: $small-font-size
  &-bar
    position: relative
    height: 8px
    border: $sidenav-stat-bar-border
    margin-bottom: $vertical-step
    border-radius: 4px
    overflow: hidden
    &-content
      position: absolute
      height: 100%
      background: $sidenav-stat-bar-bg
    &-content-red
      position: absolute
      height: 100%
      background: $sidenav-stat-bar-warning
  &, &-data
    cursor: default

.owner
  display: flex
  justify-content: center
  align-items: center
  font-size: $extra-small-font-size

  &-id
    margin-left: 6px
    padding: 3px 6px 2px 6px
    border-radius: 4px
    background: $base-gradient
    color: $white
    cursor: pointer

.text-red
  color: $sidenav-stat-bar-warning
  padding-bottom: 24px

.dark-theme
  .navigation
    background: $navigation-bg-dark-theme

  & .dropdown
    background: $basic-bg-dark-theme
    & .dropdown-item
      color: #fdfadc

      &:hover
        color: $dropdown-item-hover-color-dark-theme
        background: $dropdown-item-hover-bg-dark-theme

  & .ava
    background: $sidenav-ava-bg-dark-theme
    color: $sidenav-ava-color-dark-theme

  .stats, .actions
    background: $base-wrapper-bg-dark-theme
    box-shadow: 1px 1px 5px $base-wrapper-bg-dark-theme

  & .email::after
    border-color: #fdfadc transparent transparent transparent

  & .owner
    &-id
      background: $gray-dark-dark-theme
      color: $white

