@import "~@/theme/variables", "~@/theme/mixins"


$icons: (ae, cr2, css, dmg, gif, dwg, fw, js, pl, psd, xml, ai, csv, exe, html, json, png, rtf, zip, au, dbf, file, id, logs, mp3, ppt, pptx, svg, avi, doc, docx, fla, iso, mp4, pr, txt, br, dw, flash, jpg, jpeg, pdf, ps, xls, m3u8, m3u8-multi, epub, mkv, rar,mov, ico, xlsx, yaml, py)
.file-type
  position: relative

.tile
  .icon, .media
    height: 90px

.details
  .icon
    height: 90px
  .media
    margin: -15px -20px
    height: 315px

.icon, .media
  height: 40px
  background-repeat: no-repeat
  background-position: center center
  background-size: contain

.icon
  background-image: url('assets/file.svg')
  .rotate
    transform: rotate(15deg)
    transparant: 50%

.media
  position: relative
  .media
    position: absolute
    width: 100%
    height: 100%
    left: 3px
    top: 3px

.folder
  background-image: url('assets/folder.svg')
  cursor: pointer

.play
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  background: url('assets/play.svg') no-repeat center center
  background-size: 25% 35%
  &.multiple
    transform: translate(6px, 6px)

@each $icon in $icons
  .#{$icon}
    background-image: url('assets/#{$icon}.svg')

.private-file
  filter: grayscale(1)

.private
  position: absolute
  top: 0
  right: 50%
  transform: translate(150%, -35%)
  display: block
  height: 15px
  width: 15px
  border-radius: 50%
  background: url('assets/private.svg') white no-repeat center center
  background-size: 95%
  opacity: 1
  &.color
    filter: hue-rotate(225deg)
  &.video
    right: 0
    transform: translate(-50%, -40%)
  &.tile
    top: 0
    right: 0
    height: 25px
    width: 25px
    transform: translate(-70%, -15%)
    &.video
      transform: translate(50%, 20%)
  &.details
    top: 0
    right: 50%
    height: 25px
    width: 25px
    transform: translate(180%, -15%)

