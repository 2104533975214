@import "~@/theme/variables", "~@/theme/mixins"


$navigation-width: 280px
$toast-width: 360px

.toasts
  position: fixed
  left: 50%
  top: 120px
  min-width: $toast-width
  transform: translateX(-50%)
  z-index: 120
  &.sidenav
    padding-left: $navigation-width
    min-width: $toast-width + $navigation-width

.toast
  position: relative
  padding: 25px 15px 25px 15px
  border: 1px solid #1496f0
  background: $white
  border-radius: 5px
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16)
  text-align: center

.close
  position: absolute
  right: 10px
  top: 10px
  width: 18px
  height: 18px
  background: url(assets/close.svg) no-repeat
  background-size: 18px 18px
  cursor: pointer
  transform: rotateZ(45deg)

.dark-theme
  & .toast
    background: $modal-bg-dark-theme

