@import "~@/theme/variables", "~@/theme/mixins"


.dark-theme
  & .switcher
    & .line
      background: fade_out(#e8e9ff, 0.75)
.switcher
  position: relative
  width: 35px
  height: 20px
  padding: 3px 0
  cursor: pointer
  &.on
    opacity: 1
    .line, .head
      background: $switcher-head-color
    .head
      transform: translateX(15px)

  &.disabled
    cursor: not-allowed

.line
  height: 14px
  background: fade_out(#221F1F, 0.75)
  opacity: 0.5
  border-radius: 5px
  transition: background-color linear 0.25s

.head
  position: absolute
  top: 0
  left: 0
  width: 20px
  height: 20px
  background: #F1F1F1
  border-radius: 50%
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24)
  transition: background-color linear 0.25s, transform ease-in-out 0.25s

