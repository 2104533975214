@import "~@/theme/variables", "~@/theme/mixins"


.register-view
  padding-top: 100px

.register
  width: 350px
  margin: auto

.form
  padding: 25px 20px
  background: #FFFFFF
  border: $border-basic-light
  border-radius: $border-radius-basic

.line
  height: 1px
  background: #ECECEC
  width: 185px
  margin: 0 auto $vertical-step auto

.text-center
  text-align: center

.rocket-ship
  width: 64px
  height: 64px
  margin: 10px auto 18px
  background: url('~@/assets/rocket-ship.png') no-repeat

a
  color: #1496f0
  cursor: pointer

.dark-theme
  & .register-view
    & .form
      background: $modal-bg-dark-theme

