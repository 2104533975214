
=retina
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)
    @content

=clearfix
  &::before, &::after
    display: block
    clear: both
    content: ''
