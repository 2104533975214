@import "~@/theme/variables", "~@/theme/mixins"


@import './src/theme/theme'

#app
  position: relative
  background: $white
  min-height: 100vh

  &.has-background
    background: $app-bg
    background-size: cover

    & .content-box
      width: 100%
      margin: auto

  &.has-sidenav
    background: $white

  .main-content-wrapper
    display: flex

  .content-box
    padding: 20px
    width: 100%

.dark-theme
  & #app
    background: $app-bg-dark-theme

    &.has-sidenav
      background: $app-bg-dark-theme

    &.has-background
      background-color: $app-bg-dark-theme
      background-size: cover

