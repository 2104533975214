html
  box-sizing: border-box

*, *::before, *::after
  box-sizing: inherit

html, body
  margin: 0
  height: 100%
  min-width: $min-width

.container
  max-width: $max-width

.flex
  display: flex
