@import "~@/theme/variables", "~@/theme/mixins"


.cdn-stats-actions-datepicker
  margin: 16px 0
  padding: 10px 16px
  border-radius: 8px
  background: $white
  box-shadow: 1px 1px 5px #EBEBEB
  &:hover
    box-shadow: 1px 1px 7px #B6B9B9

  .datepicker
    &-period
      display: flex
      justify-content: center
      margin: 20px auto

      &__text
        text-transform: uppercase

      &__selector
        width: auto
        border: none
        color: $blue
        font-size: 15px
        transform: translateY(-2px)

    .dp__menu
      border: none
      min-width: auto
    .dp__input_wrap
      margin: inherit
      width: 100%

      & input
        padding: 4px
        border-radius: 3px

    .dp__calendar_header_item,
    .dp__cell_inner
      width: 30px
      height: 25px
    .dp__action_row
      width: 100%!important

      & .dp__action_buttons
        width: 100%

      & .dp__selection_preview
        display: none

.cdn-stats-actions
  height: 24px
  width: 24px
  margin: 0 auto
  border-radius: 50%
  background: $white
  box-shadow: 1px 1px 5px #EBEBEB
  cursor: pointer

  &:hover
    box-shadow: 1px 1px 7px #B6B9B9

  &__logout
    height: 23px
    width: 23px
    margin: auto
    background: url(assets/logout.svg) no-repeat
    background-size: cover

.dark-theme
  .cdn-stats-actions
    background: $base-wrapper-bg-dark-theme
    box-shadow: 1px 1px 5px $base-wrapper-bg-dark-theme
    &:hover
      box-shadow: 1px 1px 7px $base-wrapper-bg-dark-theme
    &__logout
      filter: brightness(2)

  & .cdn-stats-actions-datepicker
    background: $base-wrapper-bg-dark-theme
    box-shadow: 1px 1px 5px $base-wrapper-bg-dark-theme

  & .dp__instance_calendar
    border-radius: 3px
    background: $base-wrapper-bg-dark-theme

  & .dp__month_year_select:hover
    background: #B6B9B9
    color: $base-wrapper-bg-dark-theme

    &:hover
      background: #232423
      box-shadow: 1px 1px 7px $base-wrapper-bg-dark-theme
