@import "~@/theme/variables", "~@/theme/mixins"


.dark-theme
  & .text-field
    & input
      background: $basic-bg-dark-theme
      color: #fdfadc
    & .search + input
      background: $white
      color: $search-field-dropdown-value-color

    & textarea
      background: $basic-bg-dark-theme
      color: #fdfadc

.text-field
  position: relative

.input
  position: relative

.search
  position: absolute
  left: 0
  top: 0
  bottom: 0
  width: 36px
  background: url('~@/assets/search.png') no-repeat center center
  background-size: 16px
  +retina
    background-image: url('~@/assets/search@2x.png')
  &.active
    cursor: pointer
    background-image: url('~@/assets/search-active.png')
    +retina
      background-image: url('~@/assets/search-active@2x.png')
  & + input
    border-radius: 10px 0 0 10px
    border-color: transparent
    padding-left: 36px
    font-weight: $light
    &:focus
      border-color: transparent

.before
  position: absolute
  left: 0
  top: 0
  width: 50%
  height: 24px
  line-height: 24px
  text-align: right
  font-size: 12px
  font-weight: $light
  & + input
    padding-left: 50%

input, textarea
  $border-width: 1px
  display: block
  width: 100%
  padding: 0 5px
  height: 24px
  border: $border-width solid #D2D2D2
  font-size: $small-font-size
  outline: 0
  transition: $transition-time border-color $transition-timing
  box-shadow: none
  border-radius: 2px
  resize: none
  &.normal
    padding: 0 10px
    height: 32px
    font-size: 16px
  &.big
    height: 42px
    padding: 0 15px
    font-size: 24px
    letter-spacing: 4px
  &.extra-small
    font-size: $extra-small-font-size
  &.light
    font-size: 12px
    font-weight: $light
  &:focus
    border-color: #1496f0
  &.error
    border-color: $red
  &::placeholder
    color: #CECECE

textarea
  height: auto
  min-height: 150px
  padding: 10px 5px
  &.extra-small
    min-height: 60px
    font-size: 12px

