@import "~@/theme/variables", "~@/theme/mixins"


.circle
  position: relative
  margin: auto

.value
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)

svg
  display: block
  transform: rotate(-90deg)

circle
  transition: stroke-dashoffset 0.5s linear

.infinite
  animation: spin 4s linear infinite

@keyframes spin
  100%
    transform: rotate(360deg)

