@import "~@/theme/variables", "~@/theme/mixins"


.header
  display: flex
  align-items: center
  padding: 0 16px

  background: $header-bg
  border-bottom: $header-border

.header, .menu
  height: 66px

.logo
  margin-right: auto
  width: clamp(50px, 100%, 150px)
  height: 50px
  background: url(assets/logo.svg) no-repeat
  background-size: contain

.menu
  &-item
    position: relative
    float: right
    height: 100%
    cursor: pointer
    text-transform: uppercase
  .dropdown
    position: absolute
    left: 320px
    top: 25px

.feedback
  margin: 0 16px
  width: 24px
  height: 24px
  background: url(assets/message.svg) no-repeat
  background-size: cover

  cursor: pointer

.theme-switcher
  margin: 0 16px
  width: 24px
  height: 24px
  background: $theme-switcher-bg
  background-size: cover

  cursor: pointer

  &.dark
    background: $theme-switcher-bg-dark-theme
    background-size: cover

.search
  margin: 0 22px

.dark-theme
  .header
    background: $header-bg-dark-theme

