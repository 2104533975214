// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

// [converter] This is defined recursively in LESS, but Sass supports real loops
=make-grid-columns($i: 1, $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}")
  @for $i from 1 + 1 through $grid-columns
    $list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}"

  #{$list}
    position: relative

    // Prevent columns from collapsing when empty
    min-height: 1px

    // Inner gutter via padding
    padding-left: calc($grid-gutter-width / 2)
    padding-right: calc($grid-gutter-width / 2)

// [converter] This is defined recursively in LESS, but Sass supports real loops
=float-grid-columns($class, $i: 1, $list: ".col-#{$class}-#{$i}")
  @for $i from 1 + 1 through $grid-columns
    $list: "#{$list}, .col-#{$class}-#{$i}"

  #{$list}
    float: left

=calc-grid-column($index, $class, $type)
  @if $type == width and $index > 0
    .col-#{$class}-#{$index}
      width: percentage(calc($index / $grid-columns))

  @if $type == push and $index > 0
    .col-#{$class}-push-#{$index}
      left: percentage(calc($index / $grid-columns))

  @if $type == push and $index == 0
    .col-#{$class}-push-0
      left: auto

  @if $type == pull and $index > 0
    .col-#{$class}-pull-#{$index}
      right: percentage(calc($index / $grid-columns))

  @if $type == pull and $index == 0
    .col-#{$class}-pull-0
      right: auto

  @if $type == offset
    .col-#{$class}-offset-#{$index}
      margin-left: percentage(calc($index / $grid-columns))

// [converter] This is defined recursively in LESS, but Sass supports real loops
=loop-grid-columns($columns, $class, $type)
  @for $i from 0 through $columns
    +calc-grid-column($i, $class, $type)

// Create grid for specific class
=make-grid($class)
  +float-grid-columns($class)
  +loop-grid-columns($grid-columns, $class, width)
  +loop-grid-columns($grid-columns, $class, pull)
  +loop-grid-columns($grid-columns, $class, push)
  +loop-grid-columns($grid-columns, $class, offset)
