@import "~@/theme/variables", "~@/theme/mixins"


button
  min-width: 100px
  height: 30px
  border: 0
  color: $button-color
  background: $button-bg
  font-size: $small-font-size
  font-weight: $light
  outline: none
  cursor: pointer
  border-radius: 3px
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2)
  &.normal
    height: 32px
  &.big
    height: 36px
  &.giant
    height: 54px
  &[disabled], &.disabled
    background: #AAAAAA

  &.stroke
    min-width: auto
    background: none
    color: $button-stroke-color
    box-shadow: none
  &.black
    background: #505050
    color: $white
  &.red
    background-image: linear-gradient(to right, #F85032, #E73827)
  &.gray
    background-image: linear-gradient(to right, #AAAAAA, #a69f9f)
  &.round
    position: relative
    padding-left: 60px
    padding-right: 25px
    height: 46px
    text-transform: uppercase
    border-radius: 24px
    font-size: 18px
    &::before
      position: absolute
      left: 0
      top: 0
      width: 60px
      height: 100%
      background: url('~@/assets/plus-button.svg') no-repeat center center
      background-size: 18px 18px
      content: ''

.dark-theme
  & button
    &.stroke
      color: $button-stroke-color-dark-theme

