@import "~@/theme/variables", "~@/theme/mixins"


.dark-theme
  & input
    background: $basic-bg-dark-theme
    color: #fdfadc

input
  padding: 0 5px
  height: 24px
  border: 1px solid #D2D2D2
  font-size: $small-font-size
  outline: 0
  transition: $transition-time border-color $transition-timing
  box-shadow: none
  border-radius: 2px
  &::-webkit-placeholder
    color: red
    opacity: 0.5
  &:focus
    border-color: #1496f0
  &.error
    border-color: $red

.vdatetime-popup
  &__header
    background: #1496f0
    background-image: linear-gradient(to right, #1496f0, #3791C1)
  &__actions__button
    color: #1496f0

.vdatetime-calendar__month__day--selected
  &, &:hover
    & > span > span
      background: #1496f0

.vdatetime-time-picker__item--selected
  color: #1496f0

.vdatetime-input
  padding: 0 5px
  height: 24px
  border: 1px solid #D2D2D2
  font-size: $extra-small-font-size
  outline: 0
  box-shadow: none
  border-radius: 2px

