@import "~@/theme/variables", "~@/theme/mixins"


.download
  background: url('~@/assets/download.svg') no-repeat center center
  background-size: 163px 163px
  height: 250px
  opacity: 0.2

.download, .textarea
  height: 250px

.upload-container
  position: relative
  overflow: hidden
  input, ::-webkit-file-upload-button
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    cursor: pointer
    opacity: 0

.fullscreen
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: 100%
  opacity: 0
  z-index: 1001

