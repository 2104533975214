@import "~@/theme/variables", "~@/theme/mixins"


.language
  font-size: 18px
  color: $language-color
  font-weight: $light
  text-transform: uppercase
  cursor: pointer
  user-select: none

.dark-theme
  & .language
    color: $language-color-dark-theme

