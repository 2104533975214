@import url('//fonts.googleapis.com/css?family=Roboto:100,300,400,500&amp;subset=cyrillic')

body, input, textarea
  font-family: $font-family

body
  font-size: $base-font-size
  line-height: 1.2
  color: $base-text-color

  &.dark-theme
    background: $app-bg-dark-theme
    color: $base-text-color-dark-theme

h1, .h1
  margin: 0
  font-size: 32px
  line-height: 38px
  font-weight: $light

a, .pseudo-link
  outline: none
  text-decoration: none
  cursor: pointer
  &:hover
    text-decoration: underline

.clickable
  cursor: pointer

.button-icon
  display: inline-block
  height: 16px
  min-width: 16px
  width: 16px
  margin: 0 10px
  cursor: pointer

  &.copy
    background: url('~@/assets/copy.svg') no-repeat
    background-size: auto
    background-size: contain

[contenteditable]
  user-select: text
