@import "~@/theme/variables", "~@/theme/mixins"


.login-view
  padding-top: 100px

.login
  width: 350px
  margin: auto
  background: #FFFFFF
  border: $border-basic-light
  border-radius: $border-radius-basic
  +clearfix

.form
  padding: 25px 20px

a
  color: $login-link-color

.dark-theme
  & .login-view
    & .form
      background: $modal-bg-dark-theme

  & .login
    a
      color: $base-link-color-dark-theme

